<template>
  <div>
    <h3><span v-if="emit == 'editor'">Vložit obrázek</span><span v-else>Změnit pozadí</span></h3>
    <div
      class="img-up__file-wrapper"
      @dragleave="fileDragOut"
      @dragover="fileDragIn"
      @drop="handleFileDrop"
      v-bind:style="{ 'background-color': color }"
    >
      <input
        v-if="!file"
        type="file"
        name="file-input"
        @change="handleFileInput"
        @drop.prevent
      />
      <img v-if="src" :src="src" style="width: 100%; height: 100%; object-fit: contain; position:absolute; z-index: -1;">
      <Loader :show="loading"></Loader>
      <div v-if="!loading && !file">
        Klini nebo přetáhni obrázek do této oblasti
      </div>
      <div class="img-up__file" v-if="file && !loading" @click="removeFile"> 
        {{ file.name }}
        <span v-if="file.size">({{ file.size / 1000 }} kb)</span>
        <span class="img-up__file-remove">&times;</span>
      </div>
    </div>
    <div class="img-up__form" v-if="emit !== 'background'">
      <input
        type="text"
        class="--half-width"
        placeholder="Název obrázku* (alt tag)"
        v-model="alt"
      />
      <input
        type="text"
        class="--half-width"
        placeholder="Popis obrázku (title tag)"
        v-model="title"
      />
      <input
        type="number"
        class="--half-width"
        placeholder="Šířka obrázku (px)"
        v-model="width"
      />
      <input
        type="number"
        class="--half-width"
        placeholder="Výška obrázku (px)"
        v-model="height"
      />
      <div class="img-up__alignment" v-if="emit !== 'background'">
        <icon
          name="img_align_fill"
          class="img-up__alignment-icon"
          :class="{ 'is-active': className == 'img__responsive' }"
          @click.native="selAlign('img__responsive')"
        />
        <icon
          name="img_align_left"
          class="img-up__alignment-icon"
          :class="{ 'is-active': className == 'img__left' }"
          @click.native="selAlign('img__left')"
        />
        <icon
          name="img_align_right"
          class="img-up__alignment-icon"
          :class="{ 'is-active': className == 'img__right' }"
          @click.native="selAlign('img__right')"
        />
        <icon
          name="img_align_center"
          class="img-up__alignment-icon"
          :class="{ 'is-active': className == 'img__center' }"
          @click.native="selAlign('img__center')"
        />
        <icon
          name="img_align_cover"
          class="img-up__alignment-icon"
          :class="{ 'is-active': className == 'img__cover' }"
          @click.native="selAlign('img__cover')"
        />
      </div>
    </div>
    <div class="modal__action">
      <button
        v-if="attrs"
        class="btn btn-red --full-width"
        @click="updateImage"
      >
        Aktualizovat obrázek
      </button>
      <button v-if="attrs" class="btn --full-width" @click="deleteImage">
        Smazat obrázek
      </button>
      <button v-else class="btn btn-red --full-width" @click="insertImage">
        Vložit obrázek
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Icon from "@/components/Icon";
export default {
  props: ["show", "attrs", "emit"],
  components: {
    Icon,
  },
  data() {
    return {
      file: "",
      color: "transparent",
      loading: false,
      success: null,
      src: "",
      alt: null,
      title: null,
      className: "img__responsive",
      width: null,
      height: null,
      fileDeleted: false,
    };
  },
  methods: {
    handleFileDrop: function(e) {
      let droppedFile = e.dataTransfer.files[0];
      if (!droppedFile) return;
      this.file = droppedFile;
      this.alt = droppedFile.name.split('.').slice(0, -1).join('.');
      this.color = "transparent";
      this.uploadImage();
    },
    handleFileInput: function(e) {
      let file = e.target.files[0];
      if (!file) return;
      this.file = file;
      this.alt = file.name.split('.').slice(0, -1).join('.');
      this.uploadImage();
    },
    removeFile: function() {
      axios
        .delete("/imageUpload.php", { data: { path: this.src } })
        .then((res) => {
          if (res.data.success) {
            this.fileDeleted = true;
          }
        })
        .catch((e) => console.log(e));
      this.src = "";
      this.file = "";
    },
    fileDragIn: function() {
      // alert("oof")
      // alert("color")
      this.color = "var(--mid-gray-transparent)";
    },
    fileDragOut: function() {
      this.color = "transparent";
    },
    selAlign: function(align) {
      this.className = align;
    },
    uploadImage: function() {
      var formData = new FormData();
      if (!this.file) {
        return;
      }
      this.loading = true;
      formData.append("file", this.file);
      axios
        .post("/imageUpload.php", formData, {
          header: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          this.success = res.data.success;
          if (this.success) {
            this.src = "/" + res.data.image;
          } else {
            alert(res.data.message);
          }
        })
        .catch((e) => {
          if (e.response.status == 403) {
            this.loading = false;
            this.$root.$children[0].showLogin = true;
          }
        });
    },
    closeModal: function(removeOnClose = true) {
      if (this.fileDeleted) {
        this.$emit("delete-image", this.image);
        this.fileDeleted = false;
      }
      if(!this.attrs && this.src && removeOnClose) {
         this.removeFile();
      }
      this.src = "";
      this.alt = null;
      this.className = "img__responsive";
      this.width = null;
      this.height = null;
      this.file = "";

      this.loading = false;
      this.$emit("close-modal");
    },
    insertImage: function() {
      if(this.emit == 'background') {
        this.image.alt = 'background';
      }
      if (this.image.src && this.image.class && this.image.alt) {
        this.$emit(`insert-image-${this.emit}`, this.image);
        this.closeModal(false);
      } else {
        alert("Nejprve vyberte obrázek a doplňte jeho název");
      }
    },
    updateImage: function() {
      if (this.image.src && this.image.class && this.image.alt) {
        this.$emit("update-image", this.image);
        this.closeModal();
      } else {
        alert("Nejprve vyberte obrázek a doplňte jeho název");
      }
    },
    deleteImage: function() {
      this.$emit("delete-image");
      this.removeFile();
      this.closeModal();
    },
    updateAttrs: function() {
      if (this.attrs) {
        this.src = this.attrs.src;
        this.alt = this.attrs.alt;
        this.title = this.attrs.title;
        this.className = this.attrs.class;
        this.width = this.attrs.width;
        this.height = this.attrs.height;
        this.file = { name: this.attrs.src, size: 0 };
      }
    },
  },
  computed: {
    image: function() {
      return {
        src: this.src,
        title: this.title,
        alt: this.alt,
        class: this.className,
        width: this.width,
        height: this.height,
      };
    },
  },
  mounted() {
    this.updateAttrs();
  },
  watch: {
    show: function() {
      if (this.show) {
        this.updateAttrs();
      }
    },
  },
};
</script>

<style>
.img-up__form {
  width: 100%;
}

.img-up__alignment {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.img-up__alignment-icon,
.img-up__alignment-icon > svg,
.img-up__alignment-icon > svg > image {
  width: 60px;
  height: 60px;
}

.img-up__alignment-icon {
  background: transparent;
  border: 0;
  color: #000;
  margin: 8px 8px 8px 0;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1rem;
}

.img-up__alignment-icon:hover,
.is-active {
  background-color: rgba(152, 0, 0, 0.1);
}

.img-up__file-wrapper {
  width: 100%;
  height: 100%;
  outline: none;
  border: 3px dashed var(--mid-gray);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 3;
  color: var(--mid-gray);
  font-size: 1rem;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.img-up__file-wrapper input {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 100vh;
  height: 100%;
  z-index: 4;
  text-align: center;
  border: none;
}
.img-up__file-wrapper input:focus {
  outline: none;
}

.img-up__file-remove {
  font-weight: bold;
  z-index: 100;
}

.img-up__file {
  cursor: pointer;
  color: var(--dark-gray);
  background-color: var(--light-gray);
  opacity: 0.8;
}

.img-up__file:hover > .img-up__file-remove {
  color: var(--dark-red);
}
</style>
