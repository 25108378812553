import { Node } from 'tiptap'

export default class Iframe extends Node {

  get name() {
    return 'iframe'
  }

  get schema() {
    return {
      attrs: {
        src: {
          default: null,
        },
        class: {
          default: "--full-width",
        },
        height: {
          default: 400,
        }
      },
      group: 'block',
      parseDOM: [{
        tag: 'iframe',
        getAttrs: dom => ({
          src: dom.getAttribute('src'),
          height: dom.getAttribute('height'),
          class: dom.getAttribute('class'),
        }),
      }],
      toDOM: node => ['div',
        ['iframe', {
        src: node.attrs.src,
        class: node.attrs.class,
        height: node.attrs.height,
        frameborder: 0,
        allowfullscreen: 'true',
      }],['div', {class: 'iframe__overlay'}]
    ],
    }
  }

  commands({ type }) {
    return (attrs) => (state, dispatch) => {
      const { selection } = state;
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos;
      const node = type.create(attrs);
      const transaction = state.tr.insert(position, node);
      dispatch(transaction);
    };
  }

  get plugins() {
    return [
      
    ]
  }

}
