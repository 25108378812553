<template>
  <div class="content">
    <div class="editor__change-bg">
      <button class="btn" @click="uploadImage = 'background'">
        Změnit úvodní obrázek
      </button>
    </div>
    <div
      class="editor"
      id="editor-title"
      @keyup="runAutoSave(true)"
      @keydown="runAutoSave()"
    >
      <editor-content class="editor__content-title" :editor="titleEditor" />
    </div>
    <div
      class="editor"
      id="editor"
      @keyup="runAutoSave(true)"
      @keydown="runAutoSave()"
      @mouseup="runAutoSave(true)"
      @mousedown="runAutoSave()"
    >
      <editor-menu-bar
        :editor="editor"
        v-slot="{ commands, isActive, getMarkAttrs }"
      >
        <div class="menubar">
          <span :class="{ 'not-allowed': editHTML }">
            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.bold(), 'is-blocked': editHTML }"
              @click="commands.bold"
              title="Tučné písmo"
            >
              <icon name="bold" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.italic(),
                'is-blocked': editHTML,
              }"
              @click="commands.italic"
              title="Kurzíva"
            >
              <icon name="italic" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.strike(),
                'is-blocked': editHTML,
              }"
              @click="commands.strike"
              title="Přeškrtnuté písmo"
            >
              <icon name="strike" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.underline(),
                'is-blocked': editHTML,
              }"
              @click="commands.underline"
              title="Podtržení"
            >
              <icon name="underline" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.paragraph(),
                'is-blocked': editHTML,
              }"
              @click="commands.paragraph"
              title="Text v odstavci"
            >
              <icon name="paragraph" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': getMarkAttrs('align').textAlign == 'left',
                'is-blocked': editHTML,
              }"
              @click="commands.align({ textAlign: 'left' })"
              title="Zarovnání textu vlevo"
            >
              <icon name="align_left" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': getMarkAttrs('align').textAlign == 'center',
                'is-blocked': editHTML,
              }"
              @click="commands.align({ textAlign: 'center' })"
              title="Zarovnání textu na střed"
            >
              <icon name="align_center" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': getMarkAttrs('align').textAlign == 'right',
                'is-blocked': editHTML,
              }"
              @click="commands.align({ textAlign: 'right' })"
              title="Zarovnání textu vpravo"
            >
              <icon name="align_right" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.heading({ level: 3 }),
                'is-blocked': editHTML,
              }"
              @click="commands.heading({ level: 3 })"
              title="Nadpis úrovně 3"
            >
              <icon name="h3" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.heading({ level: 4 }),
                'is-blocked': editHTML,
              }"
              @click="commands.heading({ level: 4 })"
              title="Nadpis úrovně 4"
            >
              <icon name="h4" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.heading({ level: 5 }),
                'is-blocked': editHTML,
              }"
              @click="commands.heading({ level: 5 })"
              title="Nadpis úrovně 5"
            >
              <icon name="h5" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.bullet_list(),
                'is-blocked': editHTML,
              }"
              @click="commands.bullet_list"
              title="Odrážkový seznam"
            >
              <icon name="ul" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.ordered_list(),
                'is-blocked': editHTML,
              }"
              @click="commands.ordered_list"
              title="Číslovaný seznam"
            >
              <icon name="ol" />
            </button>

            <button
              class="menubar__button"
              :class="{
                'is-active': isActive.blockquote(),
                'is-blocked': editHTML,
              }"
              @click="commands.blockquote"
              title="Citace"
            >
              <icon name="quote" />
            </button>

            <span class="menubar__link">
              <button
                class="menubar__button"
                :class="{
                  'is-active': showLink || isActive.link(),
                  'is-blocked': editHTML,
                }"
                @click="showLinkForm(getMarkAttrs('link'))"
                title="Odkaz"
              >
                <icon name="link" />
              </button>

              <button
                v-if="isActive.link()"
                class="menubar__button"
                :class="{ 'is-active': isActive.link() }"
                @click="removeLink()"
                title="Odstranšní odkazu"
              >
                <icon name="unlink" />
              </button>
            </span>

            <button
              class="menubar__button"
              :class="{ 'is-blocked': editHTML }"
              @click="commands.horizontal_rule"
              title="Horizontální čára"
            >
              <icon name="hr" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-blocked': editHTML }"
              @click="uploadImage = 'editor'"
              title="Vložení obrázku"
            >
              <icon name="image" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-blocked': editHTML }"
              @click="showIframeModal = true"
              title="Vložení iframeu"
            >
              <icon name="embed" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-blocked': editHTML }"
              @click="commands.undo"
              title="Zpět"
            >
              <icon name="undo" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-blocked': editHTML }"
              @click="commands.redo"
              title="Vpřed"
            >
              <icon name="redo" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-blocked': editHTML }"
              @click="
                commands.createTable({
                  rowsCount: 3,
                  colsCount: 3,
                  withHeaderRow: false,
                })
              "
              title="Vložit tabulku"
            >
              <icon name="table" />
            </button>

            <span v-if="isActive.table()">
              <button
                class="menubar__button"
                @click="commands.deleteTable"
                title="Smazat tabulku"
              >
                <icon name="delete_table" />
              </button>
              <button
                class="menubar__button"
                @click="commands.addColumnBefore"
                title="Vložit sloupec před..."
              >
                <icon name="add_col_before" />
              </button>
              <button
                class="menubar__button"
                @click="commands.addColumnAfter"
                title="Vložit sloupec za..."
              >
                <icon name="add_col_after" />
              </button>
              <button
                class="menubar__button"
                @click="commands.deleteColumn"
                title="Smazat sloupec"
              >
                <icon name="delete_col" />
              </button>
              <button
                class="menubar__button"
                @click="commands.addRowBefore"
                title="Vložit řádek před..."
              >
                <icon name="add_row_before" />
              </button>
              <button
                class="menubar__button"
                @click="commands.addRowAfter"
                title="Vložit řádek za..."
              >
                <icon name="add_row_after" />
              </button>
              <button
                class="menubar__button"
                @click="commands.deleteRow"
                title="Smazat řádek"
              >
                <icon name="delete_row" />
              </button>
              <button
                class="menubar__button"
                @click="commands.toggleCellMerge"
                title="Sloučit buňky"
              >
                <icon name="combine_cells" />
              </button>
            </span>
          </span>
          <button
            class="menubar__button"
            :class="{ 'is-active': editHTML }"
            @click="editContentHTML()"
            title="Editovat HTML kód"
          >
            <icon name="code" />
          </button>
          <button
            class="menubar__button"
            @click="autoSave = !autoSave"
            :class="{ 'is-active': autoSave }"
            :title="autoSaveTitle"
          >
            <icon name="backup" />
          </button>
        </div>
      </editor-menu-bar>
      <editor-content
        class="editor__content"
        :editor="editor"
        @dblclick.native="editorContentClick()"
        v-if="!editHTML"
      />
      <div
        v-else
        id="editor-html"
        contenteditable="true"
        ref="htmlEditor"
        v-text="plainHTML"
      ></div>
    </div>
    <div class="btn-group content__admin">
      <button class="btn btn-red" @click="saveContent(true)">
        <span v-if="contentToEdit">Aktualizovat stránku</span
        ><span v-else>Vložit na web</span>
      </button>
      <button class="btn" @click="getConcept()">
        Zobrazit koncepty
      </button>
    </div>
    <modal
      v-if="showConcepts"
      v-on:close-modal="showConcepts = false"
      class="modal-full"
    >
      <div>
        <h3>Vyberte koncept k obnovení</h3>
        <div
          class="editor__concept-select"
          v-for="concept in concepts"
          :key="concept.id"
          @click.self="selectConcept(concept)"
        >
          {{ concept.name | notEmptyString }}
          <button @click="deleteConcept(concept)" class="menubar__button"><icon name="delete" /></button>
        </div>
      </div>
    </modal>
    <modal
      v-if="uploadImage"
      v-on:close-modal="closeImageUpload()"
      class="modal-full"
    >
      <image-upload
        ref="imageUpload"
        :attrs="imageToUpdate.node.attrs"
        :emit="uploadImage"
        v-on:insert-image-background="changeBackgroundImg($event)"
        v-on:insert-image-editor="insertImage($event)"
        v-on:update-image="updateImage($event)"
        v-on:delete-image="removeSelection()"
      ></image-upload>
    </modal>
    <modal
      v-if="showIframeModal"
      v-on:close-modal="closeIframeForm()"
      class="modal-half"
    >
      <div>
        <div>
          <h3>Vložit iframe</h3>
          <input
            type="text"
            v-model="iframe.node.attrs.src"
            placeholder="https://"
            class="--full-width"
          />
          <div class="btn-group">
            <input
              type="number"
              v-model="iframe.node.attrs.width"
              placeholder="Šířka (px)"
              class="--half-width"
            />
            <input
              type="number"
              v-model="iframe.node.attrs.height"
              placeholder="Výška (px)"
              class="--half-width"
            />
          </div>
        </div>

        <div class="modal__action">
          <button
            @click="addIframe()"
            class="btn btn-red --full-width"
            v-if="!this.iframe.pos"
          >
            Vložit iframe
          </button>
          <button
            class="btn btn-red --full-width"
            @click="updateIframe()"
            v-if="this.iframe.pos"
          >
            Aktualizovat iframe
          </button>
          <button
            class="btn --full-width"
            @click="removeSelection()"
            v-if="this.iframe.pos"
          >
            Smazat iframe
          </button>
        </div>
      </div>
    </modal>
    <modal
      v-if="showLink"
      v-on:close-modal="closeLinkForm()"
      class="modal-half"
    >
      <div>
        <div>
          <h3>Vložit odkaz</h3>
          <input
            type="text"
            v-model="linkUrl"
            placeholder="https://"
            class="--full-width"
          />
        </div>
        <div class="link__type">
          <div
            @click="linkClass = ''"
            :class="{ 'is-active': linkClass == '' }"
          >
            <button class="btn-link">Běžný odkaz</button>
          </div>
          <div
            @click="linkClass = 'btn btn-red'"
            :class="{ 'is-active': linkClass == 'btn btn-red' }"
          >
            <button class="btn btn-red">Červené tlačítko</button>
          </div>
          <div
            @click="linkClass = 'btn'"
            :class="{ 'is-active': linkClass == 'btn' }"
          >
            <button class="btn">Šedé tlačítko</button>
          </div>
        </div>
        <div class="modal__action">
          <button @click="addLink()" class="btn btn-red --full-width">
            Vložit odkaz
          </button>
          <button class="btn --full-width" @click="removeLink()">
            Smazat odkaz
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Icon from "@/components/Icon";
import ImageUpload from "@/components/ImageUpload";
import Modal from "@/components/Modal";
import Title from "@/components/tiptap/Title";
import Iframe from "@/components/tiptap/Iframe";
import Link from "@/components/tiptap/Link";
import Align from "@/components/tiptap/Align";
import Doc from "@/components/tiptap/Doc";
import Heading from "@/components/tiptap/Heading";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  HardBreak,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Strike,
  Underline,
  History,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Focus,
  TrailingNode,
  Placeholder,
} from "tiptap-extensions";
import Image from "@/components/tiptap/Image";
async function upload(file) {
  var formData = new FormData();
  formData.append("file", file);
  const headers = { "Content-Type": "multipart/form-data" };
  const res = await axios.post("/imageUpload.php", formData, {
    header: headers,
  });
  return res.data.image;
}
export default {
  components: {
    EditorContent,
    EditorMenuBar,
    Icon,
    "image-upload": ImageUpload,
    modal: Modal,
  },
  data() {
    return {
      linkUrl: "",
      linkClass: "",
      showLink: false,
      autoSave: true,
      conceptId: null,
      concepts: [],
      showConcepts: false,
      autoSaveInt: false,
      editHTML: false,
      uploadImage: false,
      imageToUpdate: {
        node: {},
        pos: null,
      },
      backgroundImage: "/img/def_bg.png",
      contentToEdit: "",
      showIframeModal: false,
      iframe: {
        node: {
          attrs: {},
        },
        pos: null,
      },
      titleEditor: new Editor({
        autoFocus: true,
        extensions: [
          new Title(),
          new Doc(),
          new Placeholder({
            showOnlyCurrent: false,
            emptyNodeText: (node) => {
              if (node.type.name === "title") {
                return "Doplň název stránky";
              }
            },
          }),
        ],
      }),
      editor: new Editor({
        extensions: [
          new Align(),
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [3, 4, 5] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Focus({
            className: "has-focus",
            nested: false,
          }),
          new TrailingNode({
            node: "paragraph",
            notAfter: ["paragraph"],
          }),
          new Iframe(),
          new Image(null, null, upload),
        ],
        content: `
          <h3>
            Ahoj!
          </h3>
          <p>
            Tohle je vzorový článek na web. Klikni do textu a uprav ho dle libosti. Nebo třeba přidej <a href="https://www.seznam.cz">odkaz</a>.
          </p>
          <hr>
          <h4>
            Příklad formátování:
          </h4>
          <ul>
            <li>
              Odrážkový seznam
            </li>
          </ul>
          <ol>
            <li>
              Číslovaný seznam
            </li>
          </ol>
         <blockquote><p>Citace něčeho zajímavého</p><p>Autor citátu</p></blockquote>
          </p>
        `,
      }),
    };
  },
  beforeDestroy() {
    this.editor.destroy();
    this.titleEditor.destroy();
  },
  methods: {
    saveContent: function (bool) {
      if (this.editHTML) {
        this.editContentHTML();
      }
      var name = this.titleEditor
        .getHTML()
        .replace("<h2>", "")
        .replace("</h2>", "");
      var slug = this.toSlug(name);
      var update = this.contentToEdit ? this.contentToEdit.slug : false;

      if (!name && bool) {
        alert("Nejprve zadejte název stránky!");
        return;
      }

      axios
        .post("/content.php", {
          id: this.conceptId,
          name: name,
          slug: slug,
          img: this.backgroundImage,
          content: this.editor.getHTML(),
          save: bool,
          update: update,
        })
        .then((res) => {
          if (bool) {
            this.$root.$children[0].navKey++;
            this.$router.push({ path: "/" + slug });
          } else {
            this.conceptId = res.data.data[0];
          }
        })
        .catch((e) => {
          if (e.response.status == 403) {
            this.loading = false;
            this.$root.$children[0].showLogin = true;
          }
        });
    },
    showLinkForm: function (link) {
      if (Object.keys(link).length == 0 && link.constructor === Object) {
        link = "";
      }
      if (this.editor.selection.from == this.editor.selection.to) {
        alert("Nejprve vyberte text, který chcete označit jako odkaz.");
        return;
      }
      this.linkUrl = link;
      this.showLink = true;
    },
    closeLinkForm: function () {
      this.linkUrl = "";
      this.showLink = false;
      this.linkUrl = "";
      this.linkClass = "";
    },
    closeIframeForm: function () {
      this.showIframeModal = false;
      this.iframe.node.attrs = {};
    },
    addIframe: function () {
      this.editor.commands.iframe(this.iframe.node.attrs);
      this.closeIframeForm();
    },
    updateIframe: function () {
      const transaction = this.editor.state.tr.setNodeMarkup(
        this.iframe.pos,
        this.iframe.node.type,
        this.iframe.node.attrs
      );
      this.editor.view.dispatch(transaction);
      this.closeIframeForm();
    },
    addLink: function () {
      this.editor.commands.link({ href: this.linkUrl, class: this.linkClass, target: this.linkTarget });
      this.showLink = false;
    },
    removeLink: function () {
      this.editor.commands.link({ href: null });
      this.linkUrl = "";
      this.showLink = false;
    },
    toSlug: function (str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâčďèéëěêìíïîňòóöôřšťùúůüûýžñç·/_,:;";
      var to = "aaaacdeeeeeiiiinoooorstuuuuuyznc------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }
      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
    editContentHTML: function () {
      if (this.editHTML) {
        var childNodes = this.$refs.htmlEditor.childNodes;
        var htmlString = "";
        for (var i = 0; i < childNodes.length; i++) {
          if (childNodes[i].data) {
            htmlString += childNodes[i].data;
          } else if (childNodes[i].childNodes.length) {
            htmlString += childNodes[i].childNodes[0].nodeValue
              ? childNodes[i].childNodes[0].nodeValue
              : "";
          }
          if (!htmlString) {
            alert("Chyba v zadaném HTML elementu");
          }
        }
        this.editHTML = false;
        this.editor.setContent(htmlString);
      } else {
        this.editHTML = true;
      }
    },
    insertImage: function (image) {
      this.uploadImage = false;
      this.editor.commands.image(image);
    },
    changeBackgroundImg(image) {
      this.uploadImage = false;
      this.backgroundImage = image.src;
      this.setBackgroundImg();
    },
    setBackgroundImg: function () {
      this.$store.state.bgActive = this.backgroundImage;
      this.$store.state.bgDef = this.backgroundImage;
    },
    updateImage: function (image) {
      this.uploadImage = false;
      const transaction = this.editor.state.tr.setNodeMarkup(
        this.imageToUpdate.pos,
        this.imageToUpdate.node.type,
        image
      );
      this.editor.view.dispatch(transaction);
    },
    removeSelection: function () {
      this.uploadImage = false;
      this.imageToUpdate = {
        node: {},
        pos: null,
      };
      this.closeIframeForm();
      const transaction = this.editor.state.tr.deleteSelection();
      this.editor.view.dispatch(transaction);
    },
    editorContentClick: function () {
      const { selection, state } = this.editor;
      const { from } = selection;

      const isActive = this.editor.isActive;
      if (isActive.image()) {
        this.imageToUpdate.node = state.selection.node;
        this.imageToUpdate.pos = from;
        this.uploadImage = "editor";
      } else if (isActive.link()) {
        var node = document.getSelection().baseNode;
        let range = new Range();
        range.selectNode(node);
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);
        this.showLinkForm(this.editor.activeMarkAttrs.link.href);
      } else if (isActive.iframe()) {
        this.showIframeModal = true;
        this.iframe.node = state.selection.node;
        this.iframe.pos = from;
      }
    },
    closeImageUpload: function () {
      this.$refs.imageUpload.closeModal();
      this.uploadImage = false;
      this.imageToUpdate = {
        node: {},
        pos: null,
      };
    },
    getConcept: function () {
      axios
        .get("/content.php?concept=true")
        .then((res) => {
          if (res.data) {
            this.concepts = res.data;
            this.showConcepts = true;
          }
        })
        .catch((e) => {
          if (e.response.status == 403) {
            this.loading = false;
            this.$root.$children[0].showLogin = true;
          }
        });
    },
    selectConcept(concept) {
      if (concept) {
        console.log(concept);
        this.conceptId = concept.id;
        this.backgroundImage = concept.img;
        this.setBackgroundImg();
        this.editor.setContent(concept.content);
        this.titleEditor.setContent(concept.name);
      }
      this.showConcepts = false;
    },
    deleteConcept: function(concept) {
      var confirmDelete = confirm(
        "Skutečně chcete natrvalo smazat tento koncept?"
      );
      if (!confirmDelete) {
        return;
      }
      axios
        .delete("/content.php", { data: { id: concept.id, concept: true } })
        .then(() => {
          this.concepts = this.concepts.filter(el => el.id !== concept.id);
        })
        .catch((e) => {
          if (e.response.status == 403) {
            this.$root.$children[0].showLogin = true;
          }
        });
    },
    runAutoSave: function (bool) {
      if (!this.autoSave) {
        return;
      }
      if (bool) {
        clearInterval(this.autoSaveInt);
        this.autoSaveInt = setTimeout(() => {
          this.saveContent();
        }, 3000);
      } else {
        clearInterval(this.autoSaveInt);
        this.autoSaveInt = null;
      }
    },
  },
  computed: {
    plainHTML: function () {
      return this.editor.getHTML().replace(/(<\/.*?>)/g, "$1\n");
    },
    autoSaveTitle: function () {
      if (this.autoSave) {
        return "Kliknutím vypnete automatické ukládání konceptu 3 sekundy po ukončení psaní.";
      } else {
        return "Kliknutím zapnete automatické ukládání konceptu 3 sekundy po ukončení psaní.";
      }
    },
    linkTarget: function () {
      console.log(this.linkUrl.substring(0,1) == '#');
      if(this.linkUrl.substring(0,1) == '#') {
        return '_self'
      } else {
        return '_blank'
      }
    }
  },
  filters: {
    notEmptyString: function (value) {
      if (!value) return "Koncept beze jména";
      return value;
    },
  },
  created() {
    if (this.$route.path == "/pridat-stranku") {
      this.setBackgroundImg();
      return;
    }
    axios
      .get("/content.php?slug=" + this.$route.params.slug)
      .then((res) => {
        if (res.data.length) {
          this.contentToEdit = res.data[0];
          if (this.contentToEdit) {
            this.editor.setContent(this.contentToEdit.content);
            this.titleEditor.setContent(this.contentToEdit.name);
            this.backgroundImage = this.contentToEdit.img;
          } else {
            this.titleEditor.setContent("");
          }
        } else {
          this.$router.replace({ name: "Home" });
        }
      })
      .catch(() => {
        this.$router.replace({ name: "Home" });
      });
  },
};
</script>
<style>
#editor:focus {
  outline: none;
}
#editor-title {
  text-align: center;
}
#editor-title .is-empty::before {
  content: attr(data-empty-text);
  color: #cbd5e0;
  pointer-events: none;
  height: 0;
  font-style: italic;
}
#editor-html {
  display: inline-block;
  width: 100%;
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  padding: 24px;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-sizing: border-box;
}
#editor-html:focus {
  border: 1px solid var(--mid-dark-gray);
  outline: none;
}
.menubar {
  text-align: center;
  margin-bottom: 48px;
}
.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1rem;
}

.menubar__btn-group > button {
  padding: 0.2rem 0.5rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  margin-top: 10px;
  top: -0.3rem;
  position: relative;
}

.menubar__button:hover,
.menubar__button.is-active {
  background-color: rgba(152, 0, 0, 0.1);
}

.menubar__button:focus {
  outline: none;
}

.menubar__button.is-blocked {
  pointer-events: none;
  background-color: transparent !important;
}

.not-allowed {
  cursor: not-allowed;
}

.menubar__link {
  position: relative;
}

.menubar__form {
  position: absolute;
  top: 19px;
  left: 0px;
  display: flex;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}

.menubar__form > .menubar__button {
  margin-left: 8px;
  background-color: rgba(0, 0, 0, 0.1);
}

.menubar__form > .menubar__button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.ProseMirror > * {
  position: relative;
}

.ProseMirror img,
.ProseMirror a {
  cursor: pointer;
}

.has-focus::before {
  position: absolute;
  left: -16px;
  top: calc(50% - 4px);
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: var(--dark-red);
  animation: has-focus-animation 5s steps(5, start) infinite;
  -webkit-animation: has-focus-animation 5s steps(5, start) infinite;
}

.editor__concept-select {
  padding: 16px 8px;
  margin-bottom: 8px;
  width: 100%;
  background: var(--mid-dark-gray);
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

@keyframes has-focus-animation {
  75% {
    visibility: hidden;
  }
}
@-webkit-keyframes has-focus-animation {
  75% {
    visibility: hidden;
  }
}

.ProseMirror {
  outline: -webkit-focus-ring-color auto 0px;
}

.link__type {
  margin: 8px 0;
  text-align: center;
}

.link__type > div {
  border-radius: 8px;
  display: inline-block;
  padding: 8px 16px;
}

.editor__change-bg {
  position: absolute;
  top: calc(-40vh - 10.5px);
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
}

.editor .iframe__overlay {
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.editor table td:not(:last-child) {
  border-right: 1px dotted var(--mid-gray);
}

.editor .selectedCell {
  color: #fff;
  background-color: #980000;
}
</style>
