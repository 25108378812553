<template>
  <div class="icon">
    <svg width="1rem" height="1rem">
      <image :xlink:href="require('@/assets/icons/' + name + '.svg')" width="1rem" height="1rem"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    name
  },
}
</script>

<style>
.icon {
  height: 1rem;
  width: 1rem;
}
</style>